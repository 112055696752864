.App {
	/* text-align: center; */
}

/* .blogView {
	all: initial;
	* {
		all: unset;
	}
} */

body {
	background-color: black;
}

.Home {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	font-size: 18px;
	font-family: monospace;
	position: absolute;
	word-wrap: break-word;
}

.markdown-to-html img {
	max-width: 100vw;
}

.markdown-to-html a {
	color: inherit;
}

.markdown-to-html {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: black;
	color: green;
	overflow: scroll;
	word-wrap: break-word;
}

::-webkit-scrollbar {
	width: 0;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
}

.history {
	display: flex;
	flex-direction: column;
	white-space: pre;
	/* max-height: 100%; */
	/* position: fixed; */
}

.command {
	display: flex;
	width: 100%;
}

.user {
	white-space: nowrap;
}

#commandInput {
	border: none;
	background: transparent;
	outline: 0;
	/* color: green; */
	font-size: 18px;
	font-family: monospace;
	height: 100%;
	width: 100%;
	margin-left: 0.39em;
}

#commandInput:focus {
	outline: none;
}

.text-art {
	white-space: pre;
}

.description-text-art {
	font-size: 12px;
	word-wrap: break-word;
	max-width: 100%;
	margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
	.text-art {
		font-size: 8px;
	}

	.Home {
		font-size: 12px;
	}

	.description-text-art {
		font-size: 8px;
	}

	#commandInput {
		margin-left: 0em;
		font-size: 12px;
	}
}

.codepen-iframe {
	height: 65vh;
	width: 85vw;
}